/* Add this CSS in your global styles or a relevant CSS file */
.flashing-icon svg {
    animation: flashSize 1s infinite alternate;
  }
  
  @keyframes flashSize {
    0% {
      transform: scale(1); /* Original size */
    }
    100% {
      transform: scale(1.2); /* Enlarged size */
    }
  }
  